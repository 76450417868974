import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
//Images
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const PME = () => {
    return (
        <Layout>
            <Seo
                title="Kleinunternehmen - BIKESAFE"
                description="Da aufgrund der steigenden Kraftstoffkosten und dem Bestreben zur Senkung schädlicher Emissionen immer mehr Menschen auf das Fahrrad als Transportmittel zurückgreifen, ist die Bereitstellung von praktischen Fahrradparkmöglichkeiten eine clevere Geschäftsentscheidung."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Kleinunternehmen
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <h3>Gut fürs Geschäft (und die Erde)</h3>
                        <p className="flow-text">
                            Da aufgrund der steigenden Kraftstoffkosten und dem Bestreben zur Senkung schädlicher Emissionen immer mehr Menschen auf das Fahrrad als Transportmittel zurückgreifen, ist die Bereitstellung von praktischen Fahrradparkmöglichkeiten eine clevere Geschäftsentscheidung.
                        </p>
                        <p className="flow-text">
                            Das Angebot von sicheren Fahrradabstellmöglichkeiten für Ihre Kunden und Mitarbeiter kann ihren Geschäftserfolg erheblich verbessern. Fahrradfahrer und Nicht-Fahrradfahrer werden Ihr "grünes" Denken schätzen, wenn es um die Bereitstellung sicherer Fahrradparkplätze geht. Die Möglichkeit, Ihr Geschäft oder Ihre Einrichtung ganz leicht mit dem Fahrrad erreichen zu können und das Image als umweltfreundliches Unternehmen bringen Ihnen sicher treue Kunden.                        </p>
                        <p className="flow-text">
                            Klar abgegrenzte Fahrradstellplätze vermeiden Konflikte mit Fußgängern und geparkten Fahrzeugen und halten Geländer, Wegweiser und Verkehrsschilder frei von Fahrrädern, die sonst den normalen Ladenzugang und Fußgängerverkehr behindern könnten.                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred" src="../images/pme.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">"Die neue Gestaltung funktioniert sehr gut Vielen Dank für Ihre Hilfe."
                                <strong> Simon Flack, Cycleops (Ladeninhaber), Kent</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default PME;